export const SVG_GRAPHIC_SIDE_PANEL_ICONS = {
    navigation: { url: 'assets/image/icons/graphic-side-panel/navigation.svg', name: 'gsp_navigation' },
    find_routes: { url: 'assets/image/icons/graphic-side-panel/find-routes.svg', name: 'gsp_find_routes' },
    properties: { url: 'assets/image/icons/graphic-side-panel/properties.svg', name: 'gsp_properties' },
    pid: { url: 'assets/image/icons/graphic-side-panel/pid.svg', name: 'gsp_pid' },
    help_center: { url: 'assets/image/icons/graphic-side-panel/help-center.svg', name: 'gsp_help_center' },
    navigate_to: { url: 'assets/image/icons/graphic-side-panel/navigate-to.svg', name: 'gsp_navigate_to' },
    // objects tree icons
    goto_page: { url: 'assets/image/icons/graphic-side-panel/objects-tree/goto_page.svg', name: 'gsp_goto_page' },
    connection_point: { url: 'assets/image/icons/graphic-side-panel/objects-tree/connection_point.svg', name: 'gsp_connection_point' },
    factory_symbols: { url: 'assets/image/icons/graphic-side-panel/objects-tree/factory_symbol.svg', name: 'gsp_factory_symbol' },
    line: { url: 'assets/image/icons/graphic-side-panel/objects-tree/line.svg', name: 'gsp_line' },
    arrow: { url: 'assets/image/icons/graphic-side-panel/objects-tree/arrow.svg', name: 'gsp_arrow' },
    parallelogram: { url: 'assets/image/icons/graphic-side-panel/objects-tree/parallelogram.svg', name: 'gsp_parallelogram' },
    trapez: { url: 'assets/image/icons/graphic-side-panel/objects-tree/trapez.svg', name: 'gsp_trapez' },
    ellipse: { url: 'assets/image/icons/graphic-side-panel/objects-tree/ellipse.svg', name: 'gsp_ellipse' },
    rectangle: { url: 'assets/image/icons/graphic-side-panel/objects-tree/rectangle.svg', name: 'gsp_rectangle' },
    rectangle_round: { url: 'assets/image/icons/graphic-side-panel/objects-tree/rectangle_round.svg', name: 'gsp_rectangle_round' },
    triangle: { url: 'assets/image/icons/graphic-side-panel/objects-tree/triangle.svg', name: 'gsp_triangle' },
    image: { url: 'assets/image/icons/graphic-side-panel/objects-tree/image.svg', name: 'gsp_image' },
    text: { url: 'assets/image/icons/graphic-side-panel/objects-tree/text.svg', name: 'gsp_text' },
    //
    unlinked_subtype: { url: 'assets/image/icons/canvas/linkUnlink/unlink-node.svg', name: 'gsp_unlinked_subtype' },
    unlinked_subtype_blue: { url: 'assets/image/icons/canvas/linkUnlink/unlink-node-blue.svg', name: 'gsp_unlinked_subtype_blue' },
};
